<div [class]="skinName">
  @if (appName==='film1') {
    <div>
      <svg width="306" height="281" viewBox="0 0 306 281" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_601_23)">
          <path d="M145.781 0.150034C145.637 0.2386 145.508 0.347739 145.396 0.474008C145.011 0.980008 145.156 1.30504 148.558 7.18104C150.385 10.343 151.983 13.05 152.128 13.192C152.208 13.2817 152.306 13.3527 152.417 13.4005C152.527 13.4483 152.646 13.4717 152.766 13.4689C153.025 13.4635 153.273 13.3653 153.466 13.192C153.634 13.049 155.257 10.343 157.084 7.18104C160.477 1.30504 160.62 0.981008 160.236 0.474008C160.121 0.341006 159.982 0.23108 159.826 0.150034C159.45 -0.0559663 146.129 -0.0439663 145.781 0.150034V0.150034Z" [attr.fill]="color"/>
          <path d="M108.893 6.97901C105.467 8.09701 102.533 9.09597 102.377 9.18897C102.216 9.33002 102.097 9.51295 102.034 9.71729C101.97 9.92163 101.964 10.1396 102.016 10.3472C102.076 10.5252 109.482 17.307 111.97 19.447C112.511 19.917 112.992 19.9652 113.437 19.5652C113.726 19.3242 113.858 18.798 115.12 12.822C115.877 9.25303 116.502 6.13817 116.502 5.89917C116.51 5.77028 116.49 5.64122 116.443 5.52076C116.397 5.40029 116.325 5.29114 116.233 5.20093C116.14 5.11072 116.029 5.04144 115.908 4.99781C115.786 4.95418 115.657 4.93715 115.528 4.948C115.302 4.951 112.32 5.86201 108.893 6.97901V6.97901Z" [attr.fill]="color"/>
          <path d="M69.546 26.9968C66.577 29.1498 64.016 31.0478 63.86 31.2268C63.7616 31.3215 63.691 31.4412 63.6558 31.573C63.6205 31.7048 63.622 31.8438 63.6599 31.9749C63.6921 32.2092 63.7971 32.4275 63.96 32.5989C64.36 32.9229 76.427 38.2359 76.76 38.2359C77.0261 38.209 77.2711 38.0786 77.442 37.8728C77.613 37.6671 77.6963 37.4023 77.674 37.1358C77.674 36.8468 77.374 33.8079 77.013 30.3809C76.652 26.9539 76.352 24.057 76.352 23.971C76.352 23.66 75.667 23.0947 75.294 23.0947C75.016 23.0897 73.73 23.9658 69.546 26.9968V26.9968Z" [attr.fill]="color"/>
          <path d="M42.0501 53.034C41.8701 53.192 39.9701 55.752 37.8181 58.72C34.7761 62.92 33.9111 64.1911 33.9111 64.4671C33.9111 64.8411 34.4761 65.5269 34.7881 65.5269C34.8721 65.5269 37.7581 65.8269 41.1961 66.1849C44.6341 66.5429 47.6641 66.848 47.9521 66.848C48.2195 66.8717 48.4854 66.7893 48.6924 66.6185C48.8994 66.4476 49.0308 66.2019 49.0581 65.9349C49.0581 65.5989 43.7451 53.5281 43.4201 53.1351C43.2496 52.9703 43.0304 52.865 42.7951 52.835C42.6646 52.7968 42.5262 52.7953 42.3948 52.8304C42.2634 52.8655 42.1442 52.9358 42.0501 53.034V53.034Z" [attr.fill]="color"/>
          <path d="M20.3571 91.2519C20.185 91.3366 20.0425 91.4711 19.9481 91.6379C19.6481 92.1899 15.748 104.392 15.748 104.778C15.7516 104.901 15.7798 105.023 15.8309 105.135C15.8821 105.248 15.9552 105.349 16.0459 105.433C16.1366 105.516 16.2431 105.581 16.3592 105.623C16.4753 105.666 16.5987 105.684 16.722 105.678C16.962 105.678 20.064 105.052 23.635 104.293C29.61 103.031 30.1351 102.899 30.3911 102.61C30.7761 102.165 30.7281 101.684 30.2711 101.144C28.4921 99.0869 21.362 91.2609 21.218 91.2019C20.9354 91.1061 20.6267 91.1241 20.3571 91.2519Z" [attr.fill]="color"/>
          <path d="M16.0208 178.571C15.86 178.75 15.7666 178.98 15.7568 179.22C15.7568 179.653 19.7358 191.998 20.0128 192.42C20.1529 192.581 20.335 192.7 20.5387 192.764C20.7425 192.828 20.9599 192.834 21.1668 192.781C21.3468 192.721 28.1278 185.315 30.2798 182.826C30.7368 182.285 30.7798 181.804 30.3998 181.359C30.1478 181.071 29.6188 180.938 23.6438 179.676C20.0738 178.918 16.9598 178.294 16.7188 178.294C16.5893 178.286 16.4596 178.307 16.3389 178.355C16.2182 178.402 16.1096 178.476 16.0208 178.571V178.571Z" [attr.fill]="color"/>
          <path d="M41.0641 217.798C37.8301 218.147 35.0531 218.436 34.8971 218.436C34.6456 218.497 34.4178 218.631 34.2424 218.822C34.0669 219.012 33.9517 219.25 33.9111 219.506C33.9111 219.782 34.7881 221.069 37.8181 225.253C39.9701 228.222 41.8701 230.783 42.0501 230.939C42.1443 231.037 42.2636 231.108 42.3951 231.143C42.5267 231.179 42.6654 231.177 42.7961 231.139C43.0307 231.107 43.2494 231.002 43.4211 230.839C43.7461 230.439 49.0591 218.372 49.0591 218.039C49.0354 217.805 48.929 217.588 48.7591 217.426C48.3621 217.017 48.1111 217.029 41.0641 217.798V217.798Z" [attr.fill]="color"/>
          <path d="M70.2299 248.491C66.8399 250.006 63.9779 251.316 63.8829 251.413C63.7635 251.59 63.6853 251.791 63.6539 252.002C63.6161 252.132 63.6147 252.271 63.65 252.402C63.6853 252.534 63.7558 252.653 63.8539 252.747C64.0099 252.927 66.5709 254.826 69.5399 256.978C73.7399 260.02 75.0099 260.886 75.2869 260.886C75.6599 260.886 76.3449 260.321 76.3449 260.008C76.3449 259.925 76.6449 257.039 77.0059 253.6C77.3669 250.161 77.6669 247.132 77.6669 246.844C77.6794 246.71 77.6653 246.576 77.6255 246.448C77.5857 246.32 77.5209 246.201 77.4349 246.098C77.3489 245.995 77.2434 245.91 77.1244 245.849C77.0055 245.787 76.8755 245.749 76.7419 245.738C76.5669 245.738 73.6299 246.977 70.2299 248.491V248.491Z" [attr.fill]="color"/>
          <path d="M145.781 280.571C145.638 280.482 145.508 280.373 145.396 280.247C145.011 279.741 145.156 279.416 148.558 273.54C150.385 270.378 151.983 267.671 152.128 267.529C152.208 267.439 152.306 267.368 152.416 267.32C152.526 267.272 152.646 267.248 152.766 267.251C153.025 267.256 153.274 267.355 153.466 267.529C153.634 267.672 155.257 270.378 157.084 273.54C160.477 279.417 160.62 279.74 160.236 280.247C160.121 280.38 159.982 280.489 159.826 280.571C159.45 280.777 146.129 280.764 145.781 280.571V280.571Z" [attr.fill]="color"/>
          <path d="M108.893 273.742C105.467 272.624 102.533 271.625 102.377 271.532C102.216 271.391 102.097 271.208 102.034 271.004C101.97 270.8 101.964 270.582 102.016 270.374C102.076 270.196 109.482 263.413 111.97 261.274C112.511 260.804 112.992 260.756 113.437 261.155C113.726 261.396 113.858 261.923 115.12 267.898C115.877 271.467 116.502 274.582 116.502 274.821C116.51 274.95 116.49 275.079 116.443 275.199C116.397 275.32 116.325 275.429 116.233 275.519C116.14 275.61 116.029 275.679 115.908 275.722C115.786 275.766 115.657 275.783 115.528 275.772C115.302 275.773 112.32 274.86 108.893 273.742V273.742Z" [attr.fill]="color"/>
          <path d="M196.59 6.97901C200.016 8.09701 202.95 9.09597 203.106 9.18897C203.267 9.33013 203.386 9.51296 203.449 9.71729C203.513 9.92162 203.519 10.1397 203.466 10.3472C203.406 10.5252 196.001 17.307 193.512 19.447C192.971 19.917 192.49 19.9652 192.045 19.5652C191.756 19.3242 191.624 18.798 190.362 12.822C189.605 9.25303 188.98 6.13817 188.98 5.89917C188.972 5.77028 188.992 5.64122 189.039 5.52076C189.085 5.40029 189.157 5.29114 189.25 5.20093C189.342 5.11072 189.453 5.04144 189.574 4.99781C189.696 4.95418 189.825 4.93715 189.954 4.948C190.179 4.951 193.16 5.86201 196.59 6.97901V6.97901Z" [attr.fill]="color"/>
          <path d="M235.935 26.9968C238.904 29.1498 241.465 31.0478 241.621 31.2268C241.719 31.3215 241.79 31.4412 241.825 31.573C241.86 31.7048 241.859 31.8438 241.821 31.9749C241.789 32.2092 241.684 32.4275 241.521 32.5989C241.121 32.9229 229.054 38.2359 228.721 38.2359C228.455 38.209 228.21 38.0786 228.039 37.8728C227.868 37.6671 227.784 37.4023 227.807 37.1358C227.807 36.8468 228.107 33.8079 228.468 30.3809C228.829 26.9539 229.129 24.057 229.129 23.971C229.129 23.66 229.814 23.0947 230.187 23.0947C230.464 23.0897 231.751 23.9658 235.935 26.9968V26.9968Z" [attr.fill]="color"/>
          <path d="M263.431 53.033C263.611 53.191 265.511 55.7511 267.663 58.7191C270.705 62.9191 271.57 64.1901 271.57 64.4661C271.57 64.8401 271.005 65.5259 270.693 65.5259C270.609 65.5259 267.723 65.8259 264.285 66.1839C260.847 66.5419 257.817 66.847 257.529 66.847C257.261 66.8707 256.996 66.7884 256.789 66.6175C256.582 66.4466 256.45 66.2009 256.423 65.9339C256.423 65.5979 261.736 53.5271 262.061 53.1341C262.231 52.9693 262.451 52.8641 262.686 52.834C262.816 52.7959 262.955 52.7943 263.086 52.8294C263.218 52.8645 263.337 52.9349 263.431 53.033V53.033Z" [attr.fill]="color"/>
          <path d="M285.122 91.2519C285.294 91.3366 285.437 91.4711 285.531 91.6379C285.831 92.1899 289.731 104.392 289.731 104.778C289.727 104.901 289.699 105.023 289.648 105.135C289.597 105.248 289.524 105.349 289.433 105.433C289.342 105.516 289.236 105.581 289.12 105.623C289.004 105.666 288.88 105.684 288.757 105.678C288.517 105.678 285.415 105.052 281.844 104.293C275.869 103.031 275.344 102.899 275.088 102.61C274.703 102.165 274.751 101.684 275.208 101.144C276.987 99.0869 284.117 91.2609 284.261 91.2019C284.544 91.1061 284.852 91.1241 285.122 91.2519V91.2519Z" [attr.fill]="color"/>
          <path d="M289.459 178.572C289.62 178.751 289.713 178.981 289.723 179.221C289.723 179.654 285.744 191.999 285.467 192.421C285.327 192.582 285.145 192.701 284.941 192.765C284.737 192.829 284.52 192.835 284.313 192.782C284.133 192.722 277.352 185.316 275.2 182.827C274.744 182.286 274.7 181.805 275.08 181.36C275.332 181.072 275.861 180.939 281.836 179.677C285.406 178.919 288.52 178.295 288.761 178.295C288.89 178.287 289.02 178.308 289.141 178.356C289.261 178.403 289.37 178.477 289.459 178.572V178.572Z" [attr.fill]="color"/>
          <path d="M264.416 217.798C267.65 218.147 270.427 218.436 270.583 218.436C270.834 218.497 271.062 218.631 271.238 218.822C271.413 219.012 271.528 219.25 271.569 219.506C271.569 219.782 270.692 221.069 267.662 225.253C265.51 228.222 263.61 230.783 263.43 230.939C263.336 231.037 263.217 231.108 263.085 231.143C262.954 231.178 262.815 231.177 262.685 231.139C262.45 231.107 262.232 231.002 262.06 230.839C261.735 230.439 256.422 218.372 256.422 218.039C256.446 217.805 256.552 217.588 256.722 217.426C257.122 217.017 257.371 217.029 264.416 217.798V217.798Z" [attr.fill]="color"/>
          <path d="M235.249 248.491C238.639 250.006 241.501 251.316 241.597 251.413C241.716 251.59 241.795 251.791 241.826 252.002C241.864 252.132 241.865 252.271 241.83 252.402C241.795 252.534 241.724 252.653 241.626 252.747C241.47 252.927 238.909 254.826 235.94 256.978C231.74 260.02 230.47 260.886 230.193 260.886C229.82 260.886 229.135 260.321 229.135 260.008C229.135 259.925 228.835 257.039 228.474 253.6C228.113 250.161 227.813 247.132 227.813 246.844C227.801 246.71 227.815 246.576 227.854 246.448C227.894 246.32 227.959 246.201 228.045 246.098C228.131 245.995 228.237 245.91 228.355 245.849C228.474 245.787 228.604 245.749 228.738 245.738C228.913 245.738 231.847 246.977 235.249 248.491V248.491Z" [attr.fill]="color"/>
          <path d="M196.59 273.741C200.016 272.623 202.95 271.624 203.106 271.531C203.267 271.39 203.386 271.207 203.449 271.003C203.513 270.798 203.519 270.581 203.466 270.373C203.406 270.195 196.001 263.412 193.512 261.273C192.971 260.803 192.49 260.755 192.045 261.154C191.756 261.395 191.624 261.922 190.362 267.897C189.605 271.466 188.98 274.581 188.98 274.82C188.972 274.949 188.992 275.078 189.039 275.199C189.085 275.319 189.157 275.428 189.25 275.518C189.342 275.609 189.453 275.678 189.574 275.721C189.696 275.765 189.825 275.782 189.954 275.771C190.179 275.772 193.16 274.859 196.59 273.741V273.741Z" [attr.fill]="color"/>
        </g>
        <defs>
          <clipPath id="clip0_601_23">
            <rect width="305.618" height="280.721" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  }
  @if (appName==='filmbox') {
    <div>
      @if (type === 'leftSvg') {
        <svg xmlns="http://www.w3.org/2000/svg" width="323.659" height="324.491" viewBox="0 0 323.659 324.491">
          <path fill="none" [attr.stroke]="color" stroke-width="2px" d="M0 0l.225 221.435 249.8 29.962L223.162.226z" opacity="0.2" transform="rotate(-20 246.886 39.781)"/>
        </svg>
      }
      @if (type === 'rightSvg') {
        <svg xmlns="http://www.w3.org/2000/svg" width="364.724" height="362.884" viewBox="0 0 364.724 362.884">
          <path fill="none" [attr.stroke]="color" stroke-width="2px" d="M0 0l.244 251.532L282.6 285.554 252.254.245z" opacity="0.2" transform="rotate(-70.99 188.854 133.328)"/>
        </svg>
      }
    </div>
  }


</div>