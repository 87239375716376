<ngx-extended-pdf-viewer
  [src]="pdfSrc"
  [height]="'auto'"
  [textLayer]="true"
  [showFindButton]="false"
  [showDrawEditor]="false"
  [showStampEditor]="false"
  [showTextEditor]="false"
  [showHandToolButton]="false"
  [contextMenuAllowed]="false"
  [showOpenFileButton]="false"
  [showRotateCwButton]="false"
  [showRotateCcwButton]="false"
  [showPrintButton]="false"
  [showDownloadButton]="false"
  [showSecondaryToolbarButton]="false"
  [showPropertiesButton]="false"
  [showHandToolButton]="false"
  [showScrollingButton]="false"
  [showSpreadButton]="false"
  [showSidebarButton]="false"
  [(scrollMode)]="scrollMode"
  [(pageViewMode)]="pageViewMode"
  [(spread)]="spread"
  zoom="87%"
  [minZoom]="0.5"
  [maxZoom]="1.5"
> 
</ngx-extended-pdf-viewer>