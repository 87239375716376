export const apiUrl = 'https://fblpreprodapi.tv2zdev.com/';
// export const apiUrl = 'https://fbtest2api.tv2z.nl/';
// export const apiUrl = 'https://api.filmbox.com/';
export const version = 'v1/';
export const version2 = 'v2/';
export const api = apiUrl + 'api/' + version;
export const apiV2 = apiUrl + 'api/' + version2;
export const endpointUrl = apiUrl + 'api/';
// export const settings = 'settings';
// export const token = 'token';
// export const refreshToken = 'refresh/token';
// export const login = 'login';
export const settings = 'auth/settings';
export const platformSettings = 'auth/platform_settings';
export const userSettings = 'auth/user_settings';
export const token = 'auth/token';
export const refreshToken = 'auth/refresh/token';
export const login = 'auth/login';
export const socialURL = apiUrl;
//export const Register = 'register';
export const Register = 'auth/register';
export const assetsCategory = 'assets/categories';
// export const applyCoupon = 'voucher/apply';
// export const logout = 'users/logout/devices/';
export const applyCoupon = 'payments/redeem/voucher';
export const logout = 'persona/logout/devices/';
export const homeAssets = 'assets/home';
export const catAssets = 'assets/category/';
export const partnerAssets = 'assets/content-partner/'
export const contents = '/contents';
export const assetSeeall = 'assets/seeall/';
export const contentVideoAsset = 'assets/content/video/';
//export const contentPersonDataVideo = 'assets/content/persona/video/';
// export const contentPersonDataVideo = 'assets/content/video/';
export const contentPersonDataVideo = 'persona/content/video/';
export const contentPersonDataSeries = 'persona/content/series/';
export const similarAsset = 'assets/similar/';
export const assetContentSeries = 'assets/content/series/';
// export const userContinueWatch = 'users/continue/watching';
// export const userHistory = 'users/history';
// export const watchCount = 'watch/count';
// export const wishlist = 'users/wishlist';
export const userContinueWatch = 'persona/continue/watching';
export const userHistory = 'persona/history';
export const watchCount = 'persona/watch/count';
export const wishlist = 'persona/wishlist';
export const seriesAssest = 'assets/series/';
// export const search = 'search';
// export const authentication = 'users/device/code';
// export const changePassword = 'users/reset/password';
export const search = 'assets/search';
export const authentication = 'persona/device/code';
export const changePassword = 'persona/reset/password';
export const chaneelsData = 'assets/related/seeall/livechannels/';
// export const User = 'users';
// export const feedback = 'feedback';
// export const forgotPassword = 'forgot/password';
// export const iosURL = 'user/token';
export const User = 'persona/users';
export const feedback = 'persona/feedback';
export const forgotPassword = 'auth/forgot/password';
export const iosURL = 'auth/user/token';
export const liveChannel = 'assets/livechannels';
export const liveChannelPersona = 'persona/livechannels';
// export const editUser = 'payments/stripe/redirectToUpdatePaymentMethod';
// export const resendMail = 'email/resend';
// export const staticPages = 'pages/';
// export const userDevices = 'users/devices';
// export const playerVideo = 'assets/content/persona/';
export const editUser = 'payments/svod/stripe/redirectToUpdatePaymentMethod';
export const resendMail = 'auth/email/resend';
export const staticPages = 'assets/pages/';
export const userDevices = 'persona/devices';
export const playerVideo = 'assets/content/';
export const payPerView = 'users/ppv/screen/';
// export const ppvInvoice = 'assets/invoice/';
// export const applyCoupen = 'payments/stripe/validate/coupon';
// export const checkoutTvod = 'payments/stripe/redirectToTvodPayment';
export const ppvInvoice = 'payments/invoice/';
export const applyCoupen = 'payments/stripe/svod/validate/coupon';
export const checkoutTvod = 'payments/stripe/svod/redirectToTvodPayment';
export const ppvProceed = 'payments/stripe/tvod/voucher/success';
// export const paypal = 'payments/start/transaction/ppv';
// export const userPlans = 'users/plans';
export const paypal = 'payments/paynl/tvod/start/transaction';
export const userPlans = 'payments/plans';
export const plans = 'plans';
// export const userPayment = 'users/payments';
// export const stripeResume = 'payments/stripe/resume/subscription';
// export const stripeChange = 'payments/stripe/change/subscription';
// export const stripeCancel = 'payments/stripe/cancel/subscription';
// export const stripeRedirect = 'payments/stripe/redirectToUpdatePaymentMethod';
// export const stripeCheckout = 'payments/stripe/redirectToCheckout';
// export const stripeUpdatePayment = 'payments/stripe/redirectToUpdatePayment';
// export const stripeCouponValidate = 'payments/stripe/validate/coupon';
export const userPayment = 'payments';
export const stripeResume = 'payments/stripe/svod/resume/subscription';
export const stripeChange = 'payments/stripe/svod/change/subscription';
export const stripeCancel = 'payments/stripe/svod/cancel/subscription';
export const stripeRedirect = 'payments/stripe/svod/redirectToUpdatePaymentMethod';
export const stripeCheckout = 'payments/stripe/svod/redirectToCheckout';
export const stripeUpdatePayment = 'payments/stripe/svod/redirectToUpdatePayment';
export const stripeCouponValidate = 'payments/stripe/svod/validate/coupon';
export const routeUrl = 'https://fblpreprod.tv2zdev.com/';
export const fURL = 'https://facebook.com/sharer/sharer.php?u=';
export const tURL = 'https://twitter.com/intent/tweet/?url=';
// export const continueWatching  = 'users/continue/watching';
// export const userContent = 'users/content/persona';
// export const removeContinue = 'users/continue/watching/remove';
export const continueWatching  = 'persona/continue/watching';
export const userContent = 'persona/content/persona';
export const removeContinue = 'persona/continue/watching/remove';
export const bannerPersona = 'persona/banner';
export const emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,15}$';
// export const stripeCreateSubscription = 'payments/stripe/create/subscription';
// export const stripeCompleteSubscription = 'payments/stripe/complete/subscription';
// export const stripeCreateIdealSubscription = 'payments/stripe/create/ideal/setup';
export const stripeCreateSubscription = 'payments/stripe/svod/create/subscription';
export const stripeCompleteSubscription = 'payments/stripe/svod/complete/subscription';
export const stripeCreateIdealSubscription = 'payments/stripe/svod/create/ideal/setup';
export const stripeCompleteIdealSubscription = 'payments/stripe/svod/complete/ideal/subscription';
export const stripeUpdateIdealSubscription = 'payments/stripe/svod/complete/ideal/payment';
export const stripeStatusSubscription = 'payments/stripe/subscription/status';
// export const stripeCreatePayment = 'payments/stripe/create/payment/method';
// export const stripeCompletePayment = 'payments/stripe/complete/payment/method';
// export const stripeConfirmPayment = 'payments/stripe/confirm/payment';
// export const stripeIdealPayment = 'payments/stripe/create/ideal/pm/setup';
// export const fetchPaymentMethod = 'payments/stripe/fetch/payment/methods';
// export const fetchPaymentIntent = 'payments/stripe/fetch/payment/intent';
// export const validateSession = 'validate/session';
// export const stripeCreatePaymentUpdate = 'payments/stripe/create/payment';
// export const stripeCompletePaymentUpdate = 'payments/stripe/complete/payment'
// export const stripeCreateIdealPayment = 'payments/stripe/create/ideal/payment/setup';
// export const stripeFingerPrintAPIURL = 'payments/stripe/check/fingerprint';
export const stripeCreatePayment = 'payments/stripe/svod/create/payment/method';
export const stripeCompletePayment = 'payments/stripe/svod/complete/payment/method';
export const stripeConfirmPayment = 'payments/stripe/svod/confirm/payment';
export const stripeIdealPayment = 'payments/stripe/svod/create/ideal/pm/setup';
export const fetchPaymentMethod = 'payments/stripe/svod/fetch/payment/methods';
export const fetchPaymentIntent = 'payments/stripe/svod/fetch/payment/intent';
export const validateSession = 'payments/stripe/svod/validate/session';
export const stripeCreatePaymentUpdate = 'payments/stripe/svod/create/payment';
export const stripeCompletePaymentUpdate = 'payments/stripe/svod/complete/payment'
export const stripeCreateIdealPayment = 'payments/stripe/svod/create/ideal/payment/setup';
export const stripeCompleteIdealPaymentMethod = 'payments/stripe/svod/complete/ideal/paymentMethod';
export const stripeFingerPrintAPIURL = 'payments/stripe/svod/check/fingerprint';
export const seasonsEpisodes = 'assets/contents/series/';
export const trailerInfo = 'assets/content/trailer/';
export const logoutDevices = 'persona/logout/devices';
export const payStripeCompleteIdealSubscription = 'payments/stripe/svod/completeIdealSubscription';
export const payStripeCompleteIdealPaymentMethod = 'payments/stripe/svod/completeIdealPaymentMethod';
export const voucherCode = 'payments/validate/voucherOrCoupon';
export const atvlogin = 'auth/device/atvlogin';
export const livePreference = 'persona/livechannel/preference';
export const socialLoginDataMart = 'auth/socialLogin';
//operators API list
export const bulsatOpertor ='auth/operator/contractor/validate';
export const bulsatSubcription='auth/operator/contractor/subscribe';
export const operatorsList = 'assets/operators';
export const operatorLogin = 'auth/operator/login';
export const setParentalControl = 'persona/set_parental_control'
export const contentPinValidation = 'persona/content/verify_parental_pin';
export const socialLogin = 'auth/social?provider='
export const paymentInformation = 'payments/stripe/svod/payment/information';
export const SetPaymentReminder = 'payments/stripe/svod/set/payment/reminder';
export const CancellationReasons = 'payments/stripe/svod/cancellation/reasons';
export const SetCancellationReasons ='payments/stripe/svod/set/cancellation/reasons';
export const CancellationReasonsRedirect = 'payments/stripe/svod/cancellation/reasons/redirected';
export const SetSpecialOffer ='payments/stripe/svod/set/special/offer';
export const RelevantContents = 'assets/relevant/contents';
export const CancelledPageContents ='assets/canceled/page/contents';
export const cancelSubscription = 'payments/stripe/svod/cancel/subscription';
//Paywall Home URL
export const paywallHomeURL = 'paywall/page/home';
//login with phone number URLs
export const sendPhoneVerificationCode = 'persona/send/mobile/verification';
export const emailVerfication = 'persona/send/email/verification';
export const accountVerify = 'auth/account/verify';
//hoopla and snappit
export const hooplaProcess = 'auth/hoopla/hoopla_user_process';
export const hooplaUserWatchContent = 'persona/hoopla/user_watch_content';
// payment avod 
export const checkProratedAmount = 'payments/stripe/svod/check/prorated/amount';
export const freePlanSubscription = 'payments/svod/free-plan/subscription';
//device management
export const editDeviceName = 'persona/edit/device';
// free plan cancel subscription
export const freePlanCancelSubscription = 'payments/svod/free-plan/cancel';
// tvod
export const createTvod = 'payments/stripe/tvod/payment/create';
export const completeTvod = 'payments/stripe/tvod/payment/complete';
// profile management
export const createProfile = 'auth/profile/create';
export const editProfile = 'auth/profile/update';
export const deleteProfile = 'auth/profile/generate_otp';
export const verfifyAndDelete = 'auth/profile/verify_and_delete';
