<div [class]="skinName">
  @if (data) {
    <div class="box video-box-row ">
      @if (data.component !== 'livechannel_widget' && data.component !== 'group_collection_widget' && data.component !== 'top_in_region_widget'&& data.component !== 'content_partner_widget' ) {
        <ngx-slick-carousel (init)="slickInit($event)" [id]="'slick-'+ dataIndex" #slickModal="slick-carousel" [config]="(data.orientation == 2)? cardConfig : (data.orientation == 1)? cardConfigOrientationTwo : cardConfig " class="slider video-slider multiple-items" [ngClass]="{'collection-widget-slider-two':(data.orientation == 1) , 'collection-widget-slider':(data.orientation == 2)}" (beforeChange)="beforeChange($event)" (afterChange)="afterChange($event)">
          @for (value of dataList; track value; let i = $index) {
            <div ngxSlickItem class="slide-box tile video-box lower-z-index">
              <div  [ngClass]="{'tile-media':(data.key === 'continue'),'normal-tile-media':(data.key !== 'continue') ,'normal-tile-media-two':(data.key !== 'continue' && data.orientation == 1), 'tile-media-two':(data.key === 'continue' && (data.orientation == 1)) }"  [id]="value.contentId+data.key+i" (mouseenter)="mouseEnter($event, value)" (mouseleave)="mouseLeave()" (mouseenter)="hoverIn($event,data.key, 'card', value.contentId, dataList.length, i)" (mouseleave)="hoverOut(data.key, value.contentId,i)">
                <a>
                  @if ((data.orientation == 2) ? value?.poster_landscape : ((data.orientation == 1) ? value?.poster_portrait : value?.poster_landscape)) {
                    <img (click)="redirect(value,data.key,data.title)" loading="lazy" class="tile-img" [ngClass]="{'tile-img-two':(data.orientation == 1)}"  [src]="(data.orientation == 2)? settingsService.getImage(value.poster_landscape, 'card') : ((data.orientation == 1)? settingsService.getImage(value.poster_portrait, 'card') :settingsService.getImage(value.poster_landscape, 'card')) " alt="" />
                  }
                  @if (!((data.orientation == 2) ? value?.poster_landscape : ((data.orientation == 1) ? value?.poster_portrait : value?.poster_landscape))) {
                    <div class="fallback-image" [ngClass]="(data.orientation == 1) ? 'tile-h-port': 'tile-orientation-two'">
                      @if (!value?.is_series) {
                        <img loading="lazy" src="assets/{{appName}}/img/fallback-movies.svg" alt="" />
                      }
                      @if (value?.is_series) {
                        <img loading="lazy" src="assets/{{appName}}/img/fallback-series.svg" alt="" />
                      }
                      <a class="txt-trunc card-title"> {{value?.title}} </a>
                    </div>
                  }
                  @if ((!contentPartner && value.partnerLogo && settingsService.enableModerators)) {
                    <span class="partner-logo">
                      <img loading="lazy" [src]="settingsService.getImage(value.partnerLogo, 'logo')"  alt="" />
                    </span>
                  }
                  <!-- we are removing the tvod tags for continue watching carousel -->
                  @if(data.key != 'continue'){
                    @if (value?.tvodText && data.key == 'purchases') {
                      <div class="tvod-info">
                        @if (value?.isRented) {
                          <img loading="lazy" src="/assets/{{appName}}/img/expiry-icon-gold.svg" alt="">
                        } @else {
                          <img loading="lazy" src="/assets/{{appName}}/img/rent-buy-icon.svg" alt="">
                        }
                        <span class="tvod-info-text"> {{value?.tvodText}} </span>
                      </div>
                    }
                    @if (value?.is_tvod && data.key != 'purchases') {
                      <div class="tvod-info">
                        <img loading="lazy" src="/assets/{{appName}}/img/rent-buy-icon.svg" alt="">
                        <span class="tvod-info-text">{{configService?.localeKeys?.['plans.available_to_purchase.tag'] || 'Available to purchase'}}</span>
                      </div>
                    }
                  }
                  @if (data.key == 'continue') {
                    <mat-progress-bar [value]=[value.percentage]></mat-progress-bar>
                  }
                </a>
              </div>
            </div>
          }
          @if (!noViewMore && (data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)) {
            <div ngxSlickItem class="slide-box tile video-box lower-z-index" >
              <div [id]="'view-all'+data.key+dataList.length" class="view-all-tile transition-class"  [ngClass]="{'tile-h-port':(data.orientation == 1),'tile-h-port-hover':(data.orientation == 1),'view-all-last':viewAllLast}" (click)="viewMore(data)" (mouseenter)="hoverIn($event,data.key, 'card', 'view-all', dataList.length+1, dataList.length)" (mouseleave)="hoverOut(data.key, 'view-all', dataList.length)">
                <img loading="lazy" src="assets/{{appName}}/img/view-all-plus.svg" alt="" />
                <a class="txt-trunc"> {{configService?.localeKeys?.['common_keys.view_more.part1.text'] || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.['common_keys.view_more.part2.text'] || 'more'}} </a>
              </div>
            </div>
          }
        </ngx-slick-carousel>
      }
      @if (key === 'livechannel_widget' && settingsService.enableLive) {
        <ngx-slick-carousel (init)="slickInit($event)" [id]="'slick-' + dataIndex" #slickModal="slick-carousel" [config]="liveConfig" class="slider video-slider multiple-items live-channel-widget-slider" id="channelSlider">
          @for (value of dataList; track value) {
            <div ngxSlickItem>
              <div class="live-channel">
                @if (appName === 'film1') {
                  <div class="live-img d-flex-center">
                    <img class="poster" [src]="settingsService.getImage(value.poster_landscape, 'card')">
                    <img loading="lazy" class="channel-icon" [src]="settingsService.getImage(value?.content_logo, 'logo')">
                    <div class="live-channel-img-gradient" (click)="playContentService.playVideo(value, 'play');"></div>
                    <div class="img-border-style" (click)="playContentService.playVideo(value, 'play');">
                      <app-dynamic-svg-color [color]="value?.img_border_color ? value?.img_border_color : '#FFAE00'"></app-dynamic-svg-color>
                    </div>
                    <img (click)="playContentService.playVideo(value, 'play');" class="img-border-style" loading="lazy" src="assets/{{appName}}/img/live-channels-border-arrows.svg">
                    <div (click)="playContentService.playVideo(value, 'play');" class="on-air">
                      <img src="assets/{{appName}}/img/on-air.svg">
                      <span>{{configService.localeKeys?.['live.on_air.text']}}</span>
                    </div>
                  </div>
                }
                @if (appName !== 'film1') {
                  <div >
                    <div class="live-img d-flex-center">
                      @if (value?.poster_landscape) {
                        <img class="poster" [src]="settingsService.getImage(value?.poster_landscape, 'card')">
                      }
                      @if (!value?.poster_landscape) {
                        <div class="fallback-image live-channel-fallback">
                          <img loading="lazy" src="assets/{{appName}}/img/fallback-live-channel.svg" alt="" />
                          <a class="txt-trunc card-title"> {{value?.title}} </a>
                        </div>
                      }
                    </div>
                    @if (value?.poster_landscape) {
                      <div class="on-air" (click)="playContentService.playVideo(value, 'play');">
                        <img src="assets/{{appName}}/img/on-air.svg">
                        <span>{{configService.localeKeys?.['live.on_air.text']}}</span>
                      </div>
                    }
                    <div class="lines-box-container" (click)="playContentService.playVideo(value, 'play');">
                      <div class="left-lines">
                        <app-dynamic-svg-color [type]="'leftSvg'" [color]="value?.img_border_color ? value?.img_border_color : '#ec1e24'"></app-dynamic-svg-color>
                      </div>
                      <div class="right-lines">
                        <app-dynamic-svg-color [type]="'rightSvg'" [color]="value?.img_border_color ? value?.img_border_color : '#ec1e24'"></app-dynamic-svg-color>
                      </div>
                    </div>
                    @if (value?.poster_landscape  && value?.content_logo) {
                      <img loading="lazy" class="channel-icon" [src]="settingsService.getImage(value?.content_logo, 'logo')" (click)="playContentService.playVideo(value, 'play');">
                    }
                    <!-- @if (value?.tvodText) {
                      <div class="tvod-info live-tvod-info">
                        @if (value?.isRented) {
                          <img loading="lazy" src="/assets/{{appName}}/img/expiry-icon.svg" alt="">
                        } @else {
                          <img loading="lazy" src="/assets/{{appName}}/img/rent-buy-icon.svg" alt="">
                        }
                        <span class="tvod-info-text"> {{value?.tvodText}} </span>
                      </div>
                    } -->
                    @if (value?.is_tvod) {
                      <div class="tvod-info">
                        <img loading="lazy" src="/assets/{{appName}}/img/rent-buy-icon.svg" alt="">
                        <span class="tvod-info-text">{{configService?.localeKeys?.['plans.available_to_purchase.tag'] || 'Available to purchase'}}</span>
                      </div>
                    }
                  </div>
                }
                <div class="d-flex j-center live-hover-card">
                  <span class="live-channel-actions-card-container">
                    @if (!(baseService.guest | async)) {
                      <span (click)="closeSearch();playContentService.playVideo(value, 'play');" class = "live-channel-action-card-tooltip">
                        <img class="cursor-pointer card-icon " src="assets/{{appName}}/img/card-play.svg ">
                        <span class = "live-channel-action-card-tooltip-text">{{configService?.localeKeys?.['player.play_video.btn'] || 'Play'}}</span>
                      </span>
                    }
                    <span (click)="closeSearch();navigateToLiveChannelDetail(value)" class = "live-channel-action-card-tooltip">
                      <img class="cursor-pointer card-icon " src="assets/{{appName}}/img/content_info.svg ">
                      <span class = "live-channel-action-card-tooltip-text">{{configService?.localeKeys?.see_info_title || 'See info'}}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          }
          @if ((data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)) {
            <div ngxSlickItem class="slide-box tile video-box lower-z-index">
              <div class="live-channel-view-all transition-class" [ngClass]="{'view-all-last':viewAllLast}"  [style.width] = "liveSlidesToShow  + ' !important'"  (click)="viewMore(data)">
                <img loading="lazy" class="poster" src="assets/{{appName}}/img/view-all-plus.svg">
                <a class="txt-trunc"> {{configService?.localeKeys?.['common_keys.view_more.part1.text'] || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.['common_keys.view_more.part2.text'] || 'more'}} </a>
              </div>
            </div>
          }
        </ngx-slick-carousel>
      }
      @if (key === 'content_partner_widget' && settingsService.enableModerators) {
        <ngx-slick-carousel  (init)="slickInit($event)" [id]="'slick-' + dataIndex"  #slickModal="slick-carousel" [config]="groupCollectionConfig" class="slider video-slider multiple-items group-collection-widget-slider">
          @for (value of dataList; track value; let i = $index) {
            <div ngxSlickItem class="slide-box">
              <div (click)="partnerRouting(value)" [id]="(value.unique_id || '')+data.key+i" class="group-collection-tile-media" (mouseenter)="mouseEnter($event, value)" (mouseleave)="mouseLeave()" (mouseenter)="hoverIn($event,data.key, 'group', value.contentId, dataList.length, i)" (mouseleave)="hoverOut(data.key, value.contentId,i)">
                @if (value?.poster_landscape) {
                  <img class="group-collection-tile-image" loading="lazy"  [src]="settingsService.getImage(value.poster_landscape, 'card')" alt="" />
                }
              </div>
            </div>
          }
          @if ((data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)) {
            <div ngxSlickItem class="slide-box tile video-box lower-z-index">
              <div [id]="'view-all'+data.key+dataList.length" class="group-collection-view-all-tile transition-class" [ngClass]="{'view-all-last':viewAllLast}" (click)="viewMore(data)" (mouseenter)="hoverIn($event,data.key, 'group', 'view-all', dataList.length+1, dataList.length)" (mouseleave)="hoverOut(data.key, 'view-all', dataList.length)">
                <img loading="lazy" src="assets/{{appName}}/img/view-all-plus.svg" alt="" />
                <a class="txt-trunc"> {{configService?.localeKeys?.['common_keys.view_more.part1.text'] || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.['common_keys.view_more.part2.text'] || 'more'}} </a>
              </div>
            </div>
          }
        </ngx-slick-carousel>
      }
      @if (key === 'group_collection_widget') {
        <ngx-slick-carousel  (init)="slickInit($event)" [id]="'slick-' + dataIndex"  #slickModal="slick-carousel" [config]="groupCollectionConfig" class="slider video-slider multiple-items group-collection-widget-slider">
          @for (value of dataList; track value; let i = $index) {
            <div ngxSlickItem class="slide-box">
              <div [id]="(value.contentId || '')+data.key+i" class="group-collection-tile-media" (mouseenter)="mouseEnter($event, value)" (mouseleave)="mouseLeave()" (mouseenter)="hoverIn($event,data.key, 'group', value.contentId, dataList.length, i)" (mouseleave)="hoverOut(data.key, value.contentId,i)">
                @if (value?.background_landscape) {
                  <div (click)="redirectToCollection(value, 'collection', data)" class="img-gradient"></div>
                }
                @if (value?.background_landscape) {
                  <img class="group-collection-tile-image" loading="lazy"  [src]="settingsService.getImage(value.background_landscape, 'card')" alt="" />
                }
                @if (!value?.background_landscape) {
                  <div class="fallback-image genre-fallback">
                    <img loading="lazy" src="assets/{{appName}}/img/fallback-genre.svg" alt="" />
                    <a class="txt-trunc card-title">{{value?.title}} </a>
                  </div>
                }
                @if (value?.background_landscape) {
                  <div (click)="redirectToCollection(value, 'collection', data)" class="image-name">
                    <span [ngClass]="(value.title).split(' ').length > 1 ? 'multi-grp-text': 'unique-grp-text'" class="genre-title-trunc">{{value?.title}}</span>
                    <div class="genre-link">{{(data.key === 'genre' ? configService?.localeKeys?.see_genre_title : configService?.localeKeys?.see_curation_definition) + ' >'}}</div>
                  </div>
                }
              </div>
            </div>
          }
          @if ((data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)) {
            <div ngxSlickItem class="slide-box tile video-box lower-z-index">
              <div [id]="'view-all'+data.key+dataList.length" class="group-collection-view-all-tile transition-class" (click)="viewMore(data)" (mouseenter)="hoverIn($event,data.key, 'group', 'view-all', dataList.length+1, dataList.length)" (mouseleave)="hoverOut(data.key, 'view-all', dataList.length)">
                <img loading="lazy" src="assets/{{appName}}/img/view-all-plus.svg" alt="" />
                <a class="txt-trunc"> {{configService?.localeKeys?.['common_keys.view_more.part1.text'] || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.['common_keys.view_more.part2.text'] || 'more'}} </a>
              </div>
            </div>
          }
        </ngx-slick-carousel>
      }
      @if (key === 'top_in_region_widget') {
        <ngx-slick-carousel (init)="slickInit($event)" [id]="'slick-'+ dataIndex" #slickModal="slick-carousel" [config]="topInRegionConfig" class="slider video-slider multiple-items top-in-region-widget-slider">
          @for (value of dataList; track value; let i = $index) {
            <div ngxSlickItem class="slide-box">
              <div [id]="value.contentId+data.key+i" class="most-watched-tile-media" (mouseenter)="mouseEnter($event, value)" (mouseleave)="mouseLeave()" (mouseenter)="hoverIn($event,data.key, 'region', value.contentId, dataList.length, i)" (mouseleave)="hoverOut(data.key, value.contentId,i)">
                <img class="most-watched-tile-image" loading="lazy" [src]="settingsService.getImage(value.poster_landscape, 'card')" alt="" />
                <div (click)="redirect(value,data.key,data.title)" class="top-in-region-img-gradient"></div>
                <div (click)="redirect(value,data.key,data.title)" class="number-style">
                  <img class="number-border" loading="lazy" src="assets/{{appName}}/img/top-in-region-num-border.svg" alt="">
                  <p class="number-name">{{i+1}}</p>
                </div>
                @if ((!contentPartner && value.partnerLogo && settingsService.enableModerators)) {
                  <span class="partner-logo">
                    <img loading="lazy" [src]="settingsService.getImage(value.partnerLogo, 'logo')" alt="" />
                  </span>
                }
                <!-- @if (value?.tvodText) {
                  <div class="tvod-info top-in-region-tvod-info">
                    @if (value?.isRented) {
                      <img loading="lazy" src="/assets/{{appName}}/img/expiry-icon.svg" alt="">
                    } @else {
                      <img loading="lazy" src="/assets/{{appName}}/img/rent-buy-icon.svg" alt="">
                    }
                    <span class="tvod-info-text"> {{value?.tvodText}} </span>
                  </div>
                } -->
                @if (value?.is_tvod) {
                  <div class="tvod-info">
                    <img loading="lazy" src="/assets/{{appName}}/img/rent-buy-icon.svg" alt="">
                    <span class="tvod-info-text">{{configService?.localeKeys?.['plans.available_to_purchase.tag'] || 'Available to purchase'}}</span>
                  </div>
                }
                <div class="most-watched-onhover-card">
                  <div class="movie-info">
                    <p class="meta-data-truncate">
                      @if (value?.publish_time) {
                        <span class="text-style">{{value.publish_time}}</span>
                      }
                      @if (value?.genre && genreCurationEnabled == 1) {
                        <span class="text-style">|</span>
                        <span class="text-style">{{configService.localeKeys[value.genre.split(",")[0]]}}</span>
                      }
                      @if (value?.tags && genreCurationEnabled == 0) {
                        <span class="text-style">|</span>
                        <span class="text-style">{{value.tags[0]}}</span>
                      }
                      @if ((value?.is_series == 1 && value?.total_seasons) || (value?.is_series == 0 && value.duration_min)) {
                        <span class="text-style">|</span>
                      }
                      @if (value?.is_series == 1 && value?.total_seasons) {
                        <span class="text-style">{{value.total_seasons}} {{configService.localeKeys?.['common_keys.seasons.text']}}</span>
                      }
                      @if (value?.is_series == 0 && value.duration_min) {
                        <span class="text-style">{{value.duration_min | movieDuration}}</span>
                      }
                    </p>
                  </div>
                </div>
                <div class="most-watched-actions-card">
                  <span class="most-watched-actions-card-container">
                    @if (!(baseService.guest | async)) {
                      <span class = "most-watched-action-card-tooltip">
                        <img (click)="closeSearch();playContentService.playVideo(value,'play')" class="cursor-pointer most-watched-card-icon" src="assets/{{appName}}/img/card-play.svg">
                        <span class = "most-watched-action-card-tooltip-text">{{configService?.localeKeys?.['player.play_video.btn'] || 'Play'}}</span>
                      </span>
                    }
                    @if (!value?.wishlist && !guest && !value.is_livechannel && !restrictedUser) {
                      <span class = "most-watched-action-card-tooltip">
                        <img (click)="playContentService.addToWishlist(value)"  class="cursor-pointer most-watched-card-icon" src="assets/{{appName}}/img/wishlist_add.svg">
                        <span class = "most-watched-action-card-tooltip-text">{{configService?.localeKeys?.['my_list.pop_menu_list.text'] || 'Add to wishlist'}}</span>
                      </span>
                    }
                    @if (value?.wishlist && !guest && !value.is_livechannel && !restrictedUser) {
                      <span class = "most-watched-action-card-tooltip">
                        <img (click)="playContentService.removeWishlist(value)" class="cursor-pointer most-watched-card-icon" src="assets/{{appName}}/img/wishlist_remove.svg">
                        <span class = "most-watched-action-card-tooltip-text">{{configService?.localeKeys?.['my_list.remove.text'] || 'Remove from watchlist'}}</span>
                      </span>
                    }
                    <span class = "most-watched-action-card-tooltip">
                      <img (click)="redirect(value,data.key,data.title)" class="cursor-pointer most-watched-card-icon" src="assets/{{appName}}/img/content_info.svg">
                      <span class = "most-watched-action-card-tooltip-text">{{configService?.localeKeys?.see_info_title || 'See info'}}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          }
          @if ((data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)) {
            <div ngxSlickItem class="slide-box tile video-box lower-z-index">
              <div [id]="'view-all'+data.key+dataList.length" class="top-in-region-view-all-tile transition-class" [ngClass]="{'view-all-last':viewAllLast}" (click)="viewMore(data)" (mouseenter)="hoverIn($event,data.key, 'region', 'view-all', dataList.length+1, dataList.length)" (mouseleave)="hoverOut(data.key, 'view-all', dataList.length)">
                <img loading="lazy" src="assets/{{appName}}/img/view-all-plus.svg" alt="" />
                <a class="txt-trunc"> {{configService?.localeKeys?.['common_keys.view_more.part1.text'] || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.['common_keys.view_more.part2.text'] || 'more'}} </a>
              </div>
            </div>
          }
        </ngx-slick-carousel>
      }
    </div>
  }

</div>