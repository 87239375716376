import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BaseService, SettingsService, StorageService } from './core/services';
import { ConfigurationService } from './core/services/configuration.service';
import { AnimationOptions } from 'ngx-lottie';
import { filter } from 'rxjs/operators';
import { SeoService } from './core/services/seo.service';
import { SubscriptionService } from './core/services/subscription.service';
import { environment } from 'src/environments/environment';
import { CategoriesService } from './core/services/categories.service';
import { PlanService } from './core/services/plan.service';
import { HomeService } from './core/services/home.service';
import { PlayContentService } from './core/services/play-content.service';
import { ContentService } from './core/services/content.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  @ViewChild('hoverkey') hoverKey: ElementRef;
  showHoverCardParentDiv = true;
  title = 'Tv2z-app';
  imagePath;
  showAndroidApp: boolean;
  showIosApp: boolean;
  loading = true;
  appName;
  loader;
  options: AnimationOptions = {
    path: '/assets/' + environment.appName + '/loader.json',
  };
  hasHistory: boolean;
  pageH1Tag: any;
  guest: boolean;
  showUpdateCard: boolean;
  footerLoad = false;
  orientation;
  value;
  key;
  genreCurationEnabled;
  restrictedUser;
  showHoverData = false;
  cardIMg;
  playText;
  addWishListText;
  removeWishListText;
  seeInfoText;
  removeListText;
  seasonText;
  episodeText;
  displayOnTitle;
  showTitle = false;
  scrollAddPointer;
  firstCard = 0;
  lastCard = 0;
  cardShrinkTriggering;
  checkingCardPosition;
  mouseMoveListener;
  enableRouting;
  pageDetail;
  dataTitle;
  preventClick = false;
  slickIndex;
  scaling = 'none';
  imageLoadedComplete = false;
  windowWidth;
  DeviceType;
  contentPartner

  constructor(private router: Router, public settingsService: SettingsService,private renderer: Renderer2, public contentService: ContentService,
    private baseService: BaseService, private configService: ConfigurationService, private cdRef: ChangeDetectorRef,
    public route: ActivatedRoute, private seoService: SeoService, private storageService: StorageService, public playContentService: PlayContentService,
    private subscriptionService: SubscriptionService, private categoryService: CategoriesService, private planService: PlanService, 
     public homeService: HomeService) {
    this.settingsService.showHeader = false;
    if (window.innerWidth >= 768) this.showHoverCardParentDiv = true;
    else this.showHoverCardParentDiv = false;
    this.showAndroidApp = this.baseService.showAndroidApp;
    this.showIosApp = this.baseService.showIosApp;
    this.appName = environment.appName;
    this.loader = environment.loader;
    this.footerLoad = false;
    this.imagePath = this.settingsService.imagePath;
    this.playText = this.configService.localeKeys?.["player.play_video.btn"];
    this.addWishListText = this.configService.localeKeys?.["my_list.pop_menu_list.text"];
    this.removeWishListText = this.configService.localeKeys?.["my_list.remove.text"];
    this.seeInfoText = this.configService.localeKeys?.["see_info_title"];
    this.removeListText = this.configService.localeKeys?.["my_list.remove_from_list.text"];
    this.seasonText = this.configService.localeKeys?.["common_keys.seasons_notation.text"];
    this.episodeText = this.configService.localeKeys?.["common_keys.episodes_notation.text"];
    this.genreCurationEnabled = this.settingsService.genreCurationEnabled;
    this.displayOnTitle = this.settingsService.displayOnTitle;
    this.restrictedUser = environment.isEzProxy || (this.storageService.getLocalStore('hoopla_user') == '1') ? true : false;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(
        () => {
          if (!this.showAndroidApp && !this.showIosApp && this.storageService.getLocalStore('a_t')
            && this.storageService.getLocalStore('r_t') && !this.categoryService.fetchCategoryData &&
            this.settingsService.showHeader) {
            this.categoryService.getNextVersionCategories().subscribe();
          }
          this.handleUTM();
          this.settingsService.getSeo();
          window.scrollTo(0, 0);
        });
    this.baseService.loaderSource.subscribe(data => {
      this.loading = data;
    });
    this.seoService.pageH1Tag.subscribe(data => {
      this.pageH1Tag = data;
    });
    if (this.settingsService.metroOnly) this.settingsService.showHeader = false;
    this.settingsService.showFooter = true;
    this.baseService.guest.subscribe(data => {
      this.guest = data;
    });
    this.baseService.showCardData.subscribe(data => {
      this.showUpdateCard = !data?.no_past_due;
    })
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    this.loading = false;
    setTimeout(() => {
      this.footerLoad = true;
    }, 2000);
    this.homeService.showHoverCard.subscribe((data) => {
      this.showAndMovehoverCard(data)
    })
    this.homeService.hideHoverCardImmediately.subscribe((data) => {
      if (data) {
        if(this.checkingCardPosition) clearTimeout(this.checkingCardPosition);
        if(this.cardShrinkTriggering) clearTimeout(this.cardShrinkTriggering)
        this.scaling = 'none';
        this.showPrevNextButtonFn(this.slickIndex)
        this.showHoverData = false;
        this.homeService.absoluteCardHovered = false;
      }
    })
    this.homeService.hideHoverCard.subscribe((data) => {
      if (data) {
        this.cardShrinkTriggering = setTimeout(() => {
          this.showPrevNextButtonFn(this.slickIndex)
          this.showHoverData = false;
          this.homeService.absoluteCardHovered = false;
        }, 200);
      }
    })
  }

  ngOnInit():void{}

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    if(window.innerWidth >= 768) this.showHoverCardParentDiv = true;
    else this.showHoverCardParentDiv = false;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (!this.showHoverData) {
      document.documentElement.style.pointerEvents = 'none';
      if(this.scrollAddPointer) clearTimeout(this.scrollAddPointer);
      this.scaling = 'none';
      this.homeService.hideHoverCard.next(true);
      this.scrollAddPointer = setTimeout(() => {
        document.documentElement.style.pointerEvents = 'auto';
      }, 100)
    }
  }

  handleUTM() {
    const params = new URLSearchParams(location.search);
    const urlParams = ['utm_source', 'utm_medium', 'utm_campaign', 'gclid', 'utm_term', 'utm_content'];
    const sessionParams = ['utm_source', 'utm_medium', 'utm_campaign', 'gclid', 'utm_term', 'utm_content'];

    urlParams.forEach(param => {
      const value = params.get(param);
      if (value) {
        this.storageService.setSessionStore(param, value);
      }
    });

    const utm_session_exists = sessionParams.some(param => this.storageService.getSessionStore(param));
    const url_utm_exists = urlParams.some(param => params.get(param));

    if (!url_utm_exists && utm_session_exists) {
      this.settingsService.setUTMFromSession();
    }
  }

  showAndMovehoverCard(data) {
    this.slickIndex = data.i;
    if ((data.rect || data.value)) {
      if (this.checkingCardPosition) clearTimeout(this.checkingCardPosition);
      if(this.showHoverData) {
        this.scaling = 'none';
        this.homeService.hideHoverCard.next(true)
      }
      this.imageLoadedComplete = false;
        const overlay = this.hoverKey.nativeElement;
        this.orientation = data.orientation;
        this.dataTitle = data.title;
        this.enableRouting = data.enableRouting;
        this.pageDetail = data.pageDetail;
        this.value = data.value;
        this.key = data.key;
        this.contentPartner = data.contentPartner
        const rect = data.rect;
        if ((this.key !== 'livechannel_widget' && this.key !== 'group_collection_widget' && this.key !== 'top_in_region_widget' && this.key !== 'content_partner_widget')) {
          if (this.orientation == 2 && this.value?.poster_landscape) {
            this.showTitle = false;
            this.cardIMg = this.settingsService.getImage(this.value.poster_landscape, 'card');
          }
          else if (this.orientation == 1 && this.value?.poster_portrait) {
            this.showTitle = false;
            this.cardIMg = this.settingsService.getImage(this.value.poster_portrait, 'card');
          }
          else if (this.orientation == 1 && !this.value?.poster_portrait  && this.value?.poster_landscape) {
            this.showTitle = false;
            this.cardIMg = this.settingsService.getImage(this.value.poster_landscape, 'card');
          }
          else if (!this.value?.poster_landscape && !this.value?.poster_portrait) {
            this.showTitle = true;
            this.imageLoadedComplete = true;
            if (this.value?.is_series) this.cardIMg = `assets/${this.appName}/img/fallback-series.svg`;
            else if (!this.value?.is_series) this.cardIMg = `assets/${this.appName}/img/fallback-movies.svg`;
          }

        }
        let metaCard = [];
        if (this.value?.is_series == 1) {
          if (this.value?.publish_time) metaCard.push(this.value?.publish_time)
          if (this.value?.genre && this.genreCurationEnabled == 1) metaCard.push(this.configService.localeKeys?.[this.value?.genre.split(",")[0]]);
          if (this.genreCurationEnabled == 0 && this.value?.tags) metaCard.push(this.value?.tags[0]);
          if (this.value?.total_seasons) {
            let total_seasons = this.value?.total_seasons + " " + this.configService.localeKeys?.["common_keys.seasons.text"];
            metaCard.push(total_seasons);
          }
        }
        if (this.value?.is_series == 0) {
          if (this.value?.publish_time) metaCard.push(this.value?.publish_time)
          if (this.value?.genre && this.genreCurationEnabled == 1)  {
            // in tvod, live channels cards may come in the carousel.Hence this code.
            let genreValue: any;
              genreValue = this.configService.localeKeys?.[this.value?.genre.split(",")[0]];
              metaCard.push(genreValue);
          }
          if (this.genreCurationEnabled == 0 && this.value?.tags) metaCard.push(this.value?.tags[0]);
          if (this.value?.duration_min) metaCard.push(this.transform(this.value?.duration_min))
        }
        let distanceFromLeft = rect.left;
        let distanceFromRight = window.innerWidth - rect.right;
        if (distanceFromLeft < (rect.width)/2) this.firstCard = 1;
        else if (distanceFromRight < (rect.width)/2) this.lastCard = 1;
        else {
          this.firstCard = 0;
          this.lastCard = 0;
        }
        this.value.metaCard = metaCard.join(" | ");
        this.showHoverData = true;
        this.homeService.absoluteCardHovered = true;
        this.hidePrevNextButtonFn(this.slickIndex);
        overlay.style.width = `${rect.width}px`;
        overlay.style.left = `${rect.left + window.scrollX}px`;
        overlay.style.top = `${rect.top + window.scrollY}px`;
        this.scaling = 'scale';
      if(this.mouseMoveListener) this.mouseMoveListener();
      this.checkingCardPosition = setTimeout(() => {
        this.mouseMoveListener = this.renderer.listen(document, 'mousemove', (event: MouseEvent) => this.onMouseMoveOnScreen(event))
      }, 200);
    }
  }

  imageLoaded(){
    this.imageLoadedComplete = true;
  }

  transform(data) {
    let min = data;
    if (min > 60) {
      let hours = Math.floor(min / 60);
      let minutes = min % 60;
      return `${hours} ${this.configService?.localeKeys?.["common_keys.hr_letter.text"] || 'h'} ${minutes} ${this.configService?.localeKeys?.["common_keys.min_letter.text"] || 'm'}`
    } else {
      return `${min} ${this.configService?.localeKeys?.["common_keys.min_letter.text"] || 'm'}`
    }
  }

  showPrevNextButtonFn(index){
    let buttonDiv: HTMLElement = document.querySelector('#slick-'+ index);
    if (buttonDiv) {
      let nextButton: HTMLButtonElement = buttonDiv.querySelector('.slick-next');
      let prevButton: HTMLButtonElement = buttonDiv.querySelector('.slick-prev');
      if (nextButton) nextButton.style.cssText += 'opacity: 1;'
      if (prevButton) prevButton.style.cssText += 'opacity: 1;'
    }
  }

  hidePrevNextButtonFn(index){
    let buttonDiv: HTMLElement = document.querySelector('#slick-'+ index);
    if (buttonDiv) {
      let nextButton: HTMLButtonElement = buttonDiv.querySelector('.slick-next');
      let prevButton: HTMLButtonElement = buttonDiv.querySelector('.slick-prev');
      if (nextButton) nextButton.style.cssText += 'opacity: 0;'
      if (prevButton) prevButton.style.cssText += 'opacity: 0;'
    }  
  }

  stopListening() {
    if (this.mouseMoveListener)  this.mouseMoveListener();
  }

  onMouseMoveOnScreen(event: MouseEvent) {
    clearTimeout(this.checkingCardPosition)
    let mouseX = event.clientX;
    let mouseY = event.clientY;
    let cardPosition = this.hoverKey.nativeElement.getBoundingClientRect();
    if (mouseX >= cardPosition.left && mouseX <= cardPosition.right && mouseY >= cardPosition.top && mouseY <= cardPosition.bottom) {
      this.stopListening()
    } else {
      this.scaling = 'none';
      this.homeService.hideHoverCard.next(true);
      this.stopListening()
    }
  }

  onMouseLeave() {
    if (this.checkingCardPosition) clearTimeout(this.checkingCardPosition);
    this.scaling = 'none';
    this.homeService.hideHoverCard.next(true);
    this.homeService.absoluteCardHovered = false;
  }

  redirect(video, key, title) {
    if (this.enableRouting) {
      if(this.checkingCardPosition) clearTimeout(this.checkingCardPosition);
      this.scaling = 'none';
      this.showPrevNextButtonFn(this.slickIndex);
      this.showHoverData = false;
      this.homeService.absoluteCardHovered = false;
      this.homeService.triggerDatamartForHoverCardValue = video;
      this.homeService.triggerDatamartForHoverCard.next(true);
      if (video.is_livechannel) {
        this.baseService.storeEPGValue(video.slug);
        if(this.settingsService.epgEnabled)this.contentService.autoPlayVideo = true;
        const slug = video?.slug || video?.content_slug
        this.router.navigate(['/live', slug]);
      } else if (video.is_series) {
        const seriesSlug = video?.seasons_slug || video?.series_slug;
        const contentSlug = video?.content_slug;
        if (video.watched_duration) {
          this.contentService.contentSlug = contentSlug;
          this.contentService.seriesSlug = seriesSlug;
          this.contentService.seekValue = video.watched_duration;
        } else {
          this.contentService.seekValue = 0;
        }
        this.router.navigate(['/series', seriesSlug, contentSlug]);
      } else if (key === 'video' && this.pageDetail === 'live') {
        if(this.settingsService.epgEnabled)this.contentService.autoPlayVideo = true;
        this.router.navigate(['/live/player', 'video', video.content_id]);
      } else {
        this.router.navigate(['/video', video.collection_slug, video.content_slug]);
      }
      document.documentElement.style.pointerEvents = 'none';
      setTimeout(()=>{
        document.documentElement.style.pointerEvents = 'auto'; 
      },150)
    }
  }

  CallingPlayContentService(value , type){
    if(type == 'play'){
      if(this.checkingCardPosition) clearTimeout(this.checkingCardPosition);
      this.scaling='none';
      this.showPrevNextButtonFn(this.slickIndex)
      this.showHoverData = false;
      this.homeService.absoluteCardHovered = false;
      this.playContentService.playVideo(value, 'play');
    }
    else if(type == 'add') this.playContentService.addToWishlist(value);
    else if(type == 'remove') this.playContentService.removeWishlist(value);
  }

  removeContinue(continueId: string) {
    this.preventClick = true;
    this.homeService.removeContinueWatch(continueId).subscribe((data) => {
      if (data) {
        this.preventClick = false;
        this.homeService.updateUserContentFlag = true;
        this.homeService.updateUserContent.next(true);
        this.scaling='none';
        this.showPrevNextButtonFn(this.slickIndex)
        this.showHoverData = false;
        this.homeService.absoluteCardHovered = false;
      }
    });
  }

  domLoaded() {
    this.loading = false;
  }

}
