import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { continueWatching, homeAssets, removeContinue, userContent, bannerPersona } from 'src/app/shared/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';
import { environment } from 'src/environments/environment';
import { WishlistService } from './wishlist.service';
import { SettingsService } from './settings.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public contentWishlist = new BehaviorSubject<Array<string>>([]);
  public contentWishlistData = new BehaviorSubject<Array<object>>([]);
  public showHoverCard = new BehaviorSubject<{}>({});
  public hideHoverCard = new BehaviorSubject<boolean>(true);
  public hideHoverCardImmediately = new BehaviorSubject<boolean>(false);
  public userData = new BehaviorSubject<Array<object>>([]);
  public triggerDatamartForHoverCard = new BehaviorSubject<boolean>(false);
  triggerDatamartForHoverCardValue;
  updateUserContent = new BehaviorSubject<boolean>(true);
  updateUserContentFlag = true;
  updateCategoryContentFlag = true;
  public wishlistCount;
  removeListFlage = false;
  absoluteCardHovered = false;

  constructor(private http: HttpClient, private uikitService: UikitService, private wishlistService: WishlistService,
    private settingsService: SettingsService, private route: ActivatedRoute, private storageService: StorageService) { }
  //this service calling for get home content list details
  assets(): Observable<any> {
    const homeAssetsURL = environment.apiUrl + environment.v3_version + homeAssets;
    const params: HttpParams = new HttpParams()
      .set('d_type', 'web')
      .set('version','v1');
    return this.http.get(homeAssetsURL, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  continueWatching(data): Observable<any> {
    let params: HttpParams;
    const continueWatchURl = environment.apiUrl + environment.version + continueWatching;
    params = new HttpParams()
      .set('d_type', 'web')
      .set('skip', data.skip)
      .set('take', data.take)
    if (data?.category) {
      params = params.append('category', data?.category)
    }
    return this.http.get(continueWatchURl, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  userContent(slug?: any): Observable<any> {
    try {
      let partnerSlug = this.route.snapshot.paramMap.get('partnerName');
      let partnerUniqueId;
      if (partnerSlug) {
        let findPartner = this.settingsService.contentPartners.find(obj => obj.slug == partnerSlug);
        partnerUniqueId = findPartner.unique_id;
      }
      const title = slug?.title;
      const skip = slug?.skip;
      const take = slug?.take;
      let params: HttpParams;
      const userContentAPI = environment.apiUrl + environment.version + userContent;
      params = new HttpParams()
        .set('d_type', 'web')
        .set('version','v1');
      if (title == 'wishlist' && !this.removeListFlage) {
        params = params.append('skip', skip)
          .append('take', take);
      }
      if (!this.route.snapshot.queryParams.partner_id || !partnerSlug) {
        if (slug?.category) {
          params = params.append('category', slug?.category)
        }
      }
      if (this.route.snapshot.queryParams.partner_id || partnerSlug) {
        if (partnerSlug) params = params.append('content_partner_id', partnerUniqueId)
        else params = params.append('content_partner_id', this.route.snapshot.queryParams.partner_id)
      }
      if (slug?.content_type) {
        params = params.append('content_type', slug.content_type)
      }
      if (this.wishlistService.seekCount && this.removeListFlage) {
        const seekVal = this.wishlistService.seekCount + this.settingsService.takeValue;
        params = params.append('skip', '0')
          .append('take', seekVal);
      }
  
      const profileId = this.storageService.getSessionStore('profileId');
      const uId = this.storageService.getLocalStore('u_id');
      params = params.append('profile_id', (profileId || uId));
  
      return this.http.get(userContentAPI, { params }).pipe(
        map((response: any) => {
          if (response.status_code === 200) {
            return response.data;
          }
          else {
            this.uikitService.notifyError(response);
          }
        }), catchError((error) => {
          if (error?.error?.error?.code !== 1002) {
            this.uikitService.notifyError(error);
          }
          return error;
        })
      );
    } catch (error) {
      console.log(error)
    }
  }

  removeContinueWatch(continueId: string): Observable<any> {
    const removeContinueAPI = environment.apiUrl + environment.version + removeContinue;
    const postData = { d_type: 'web', continue_id: continueId };
    return this.http.post(removeContinueAPI, postData).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  bannerpersonainfo(banner, region, locale, partnerId?): Observable<any> {
    banner = banner.map((slide) => {
      return slide.content_id;
    })
    const bannerPersonaURl = environment.apiUrl + environment.version + bannerPersona;
    let data;
    if (partnerId) {
      data = { "banner_content_id": banner, "d_type": 'web', "region": region, "locale": locale, "content_partner_id": partnerId,'version':'v1' };
    } else data = { "banner_content_id": banner, "d_type": 'web', "region": region, "locale": locale, 'version':'v1' };

    const profileId = this.storageService.getSessionStore('profileId');
    const uId = this.storageService.getLocalStore('u_id');
    data['profile_id'] = profileId || uId;

    return this.http.post(bannerPersonaURl, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error.error && error.error.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  addPersonaData(personaData, datas) {
    const continueData = personaData.find((persona: any) => {return persona.key === 'continue'});
    const wishlistData = personaData.find((persona: any) => {return persona.key === 'wishlist'});
    const topPicksData = personaData.find((persona: any) => {return persona.key === 'top_picks_for_you'});
    const myPurchaseData = personaData.find((persona: any) => {return persona.key === 'purchases'});
  
    datas = datas.map((item) => {
      if (item.component === 'continue_watching_widget')  {
        return {...item, ...continueData};
      } else if (item.component === 'my_list_widget') {
        if(this.absoluteCardHovered){
          this.hideHoverCardImmediately.next(true)
        }
        return {...item, ...wishlistData};
      } else if (item.component === 'top_picks_for_you_widget')  {
        return {...item, ...topPicksData};
      } else if (item.component === 'because_you_watched_widget') {
        let watchedData = personaData.find((persona: any) => {
          return persona.key === item.key
        });
        return {...item, ...watchedData };
      } else if (item.component === 'my_purchases_widget') {
        if(myPurchaseData?.purchases)this.storageService.setLocalStore('userPurchases', myPurchaseData.purchases);
        return {...item, ...myPurchaseData};
      } else return item;
    });
    const filteredDatas = datas.filter(obj => {return obj?.list.length > 0})
    return filteredDatas;
  }

  getwebfallImage(slides) {
    const bannerTemplate = this.settingsService.displayBannerTemplate;
    const imagePath = this.settingsService?.imagePath;

    return slides.map((slide) => {
      let img_first, img_second, port_webp, port_jpg, logo_webp, logo_jpg, homeTile_webp, homeTitle_jpg, onlyLand_Img_webp, onlyLand_Img_jpg;

      const getSlideImage = (image, type) => ({
        webp: this.settingsService.getImage(image, type),
        jpg: imagePath + image
      });

      if (slide?.background_landscape) {
        const { webp, jpg } = getSlideImage(slide.background_landscape, 'banner');
        img_first = webp;
        img_second = jpg;
      } else {
        const { webp, jpg } = getSlideImage(slide.poster_landscape, 'banner');
        img_first = webp;
        img_second = jpg;
      }

      if (slide?.poster_portrait) {
        const { webp, jpg } = getSlideImage(slide.poster_portrait, 'card');
        port_webp = webp;
        port_jpg = jpg;
      }

      if (slide?.content_logo) {
        const { webp, jpg } = getSlideImage(slide.content_logo, 'logo');
        logo_webp = webp;
        logo_jpg = jpg;
        homeTile_webp = webp;
        homeTitle_jpg = jpg;
      }

      if (bannerTemplate === 'only_landscape_banner') {
        if (slide?.poster_landscape) {
          const { webp, jpg } = getSlideImage(slide.poster_landscape, 'banner');
          onlyLand_Img_webp = webp;
          onlyLand_Img_jpg = jpg;
        } else {
          const { webp, jpg } = getSlideImage(slide.background_landscape, 'banner');
          onlyLand_Img_webp = webp;
          onlyLand_Img_jpg = jpg;
        }
      }

      const assignBackground = (first, second) => ({
        bannerbg_webp: first || '',
        bannerbg_fallback: second || ''
      });

      if (bannerTemplate === 'portrait_banner_with_metadata') {
        const bannerData = assignBackground(`var(--home-banner-gradient-bg1), var(--home-banner-gradient-bg2), url(${img_first})`, `var(--home-banner-gradient-bg1), var(--home-banner-gradient-bg2), url(${img_second})`);
        return {
          ...slide,
          ...bannerData,
          bannerimg_first: img_first || '',
          bannerbg_second: img_second || '',
          poster_image_webp: port_webp || '',
          poster_image_jpg: port_jpg || '',
        };
      }

      if (bannerTemplate === 'landscape_banner_with_metadata' || bannerTemplate === 'landscape_banner_mobile_square_with_metadata') {
        const bannerData = assignBackground(`url(${img_first})`, `url(${img_second})`);
        return {
          ...slide,
          ...bannerData,
          bannerimg_first: img_first || '',
          bannerbg_second: img_second || '',
          logo_image_webp: logo_webp || '',
          logo_image_jpg: logo_jpg || '',
          home_page_title_image_webp: homeTile_webp || '',
          home_page_title_image_jpg: homeTitle_jpg || '',
        };
      }

      if (bannerTemplate === 'only_landscape_banner') {
        return {
          ...slide,
          onlyland_banner_image_webp: onlyLand_Img_webp || '',
          onlyland_banner_image_jpg: onlyLand_Img_jpg || ''
        };
      }
    });
  }

}
