export const environment = {
    production: true,
    environmentName:'sit',
    apiUrl: 'https://demoapi.tv2z.nl/api/',
    socialUrl:'https://demoapi.tv2z.nl/api/',
    version:'v2/',
    v3_version: 'v3/',
    routeUrl:'https://demoott.tv2z.nl',
    cookieDomain:'filmbox.com',
    paywallURL: 'https://demoott.tv2z.nl',
    appName: 'tv2z',
    isEzProxy: false,
    ezProxyUrl: '',
    loader: 'tv2z',
    appVersion: '1.0.0',
    htmlAppName: 'film1',
    skinName: 'skin-tv2z',
}
