import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgxExtendedPdfViewerModule, PageViewModeType, ScrollModeType } from 'ngx-extended-pdf-viewer';
import { SettingsService } from 'src/app/core/services';

@Component({
  selector: 'app-pdf-viewer-page',
  standalone: true,
  imports: [ NgxExtendedPdfViewerModule],
  templateUrl: './pdf-viewer-page.component.html',
  styleUrl: './pdf-viewer-page.component.scss'
})
export class PdfViewerPageComponent implements OnDestroy {
  pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  public scrollMode = ScrollModeType.page;

  public pageViewMode: PageViewModeType = 'single';

  public spread: 'off' | 'odd' | 'even' = 'even';

  constructor(public settingsService: SettingsService){
    this.settingsService.showHeader = false;
    this.settingsService.showFooter = false;
  }

  ngOnDestroy(): void {
    this.settingsService.showHeader = true;
    this.settingsService.showFooter = true;
  }
}
