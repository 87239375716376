import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService, SettingsService, StorageService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class LoginModeGuard  {
  constructor(private router: Router, private baseService: BaseService, private settingsService: SettingsService,
              private storageService: StorageService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const uId = this.storageService.getLocalStore('u_id');
      const profileId = this.storageService.getSessionStore('profileId');
      if(this.baseService.loginMode === 1 && !uId) {
        this.settingsService.showHeader = false;
        this.router.navigateByUrl('/auth/signup');
      } else if (!profileId && this.settingsService.maxUserProfiles > 0 && uId) {
        this.router.navigateByUrl('/auth/user-profiles');
      } else {
        this.settingsService.showHeader = true;
        return true;
      }  
  }
  
}
