import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dynamic-svg-color',
  templateUrl: './dynamic-svg-color.component.html',
  styleUrls: ['./dynamic-svg-color.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class DynamicSvgColorComponent implements OnInit {
  @Input() color;
  @Input() type;
  appName = '';
  skinName = environment.skinName;
  constructor() { }

  ngOnInit(): void {
    this.appName = environment.appName;
  }

}
