import { Injectable } from '@angular/core';
import {
  contentVideoAsset, contentPersonDataVideo, similarAsset, userContinueWatch, assetContentSeries, userHistory, watchCount, contentPersonDataSeries, trailerInfo, api, contentPinValidation
} from 'src/app/shared/constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { ConfigurationService } from './configuration.service';
import { Router } from '@angular/router';
import { ApplicationService } from './application.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  contentSlug: any;
  seekValue: any;
  seriesSlug: any;
  seasonId: any;
  autoPlayVideo: boolean = false;
  play: boolean = false;
  start: boolean = false;
  trailer: any;
  epgRoute = false;
  livePlayerActive: boolean = false;
  languageChanged = new Subject()
  circularLoader = false;
  channelsStickTop = false;
  mouseWheelActivate = false;
  contentId:any;
  webSocketUrl;
  socket:WebSocket;
  activeRouteInterval;
  pingIntervalId: any;
  connectUrl: any;
  connectionTimeout: number = 2 * 60 * 60 * 1000;
  connectionTimer: any;
  public socketResponse = new BehaviorSubject(false);
  public concurrentErrorMsg =  new BehaviorSubject(false);
  public liveConcurrentErrorMsg =  new BehaviorSubject(false);
  playerHeight: any;
  playerWidth: any;
  playerHolaWidth: any;
  windowWidth = window.innerWidth;
  openPayment = new BehaviorSubject(false);
  autoPlay = false;
  updateTvodInfo = new BehaviorSubject({});

  constructor(private http: HttpClient, private uikitService: UikitService,
              private storageService: StorageService, private settingsService : SettingsService, private baseService: BaseService,
               private configService: ConfigurationService, private router: Router, private appService: ApplicationService) {
                 this.connectUrl = '';
                this.webSocketUrl = this.settingsService.webSocketUrl;
                this.activeRouteInterval = this.settingsService.activeRouteInterval;
               }

  getCategoryDataById(id: string): Observable<any> {
    // this.settingsService.spinner = true;
    const contentURL = environment.apiUrl + environment.version + contentVideoAsset + id;
    const params = new HttpParams()
      .set('d_type', 'web')
      .set('version','v1');
    return this.http.get(contentURL, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data.content;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }
  getContentPersonData(id: string, isPLayedFromplayer?: string) {
    let streamingTYpe = this.baseService.checkBrowser();
    const contentURL = environment.apiUrl + environment.version + contentPersonDataVideo + id;
    let params = new HttpParams()
      .set('d_type', 'web');
      params = params.append('streaming_type', streamingTYpe)
      params = params.append('supports_drm', this.settingsService.enableDrm)
    if(isPLayedFromplayer === '1')
      params = params.append('is_played_from_player', isPLayedFromplayer)
    return this.http.get(contentURL, { params }).pipe(
      map(
        (response: any) => {
            return response;
        }
        , (error: HttpErrorResponse) => {
          return error;
        }
      )
    );
  }

  getSimilarAssestById(id: string) {
    const similarAssetURL = environment.apiUrl + environment.version + similarAsset + id;
    const params = new HttpParams()
      .set('d_type', 'web')
      .set('version', 'v1');
    return this.http.get(similarAssetURL, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            return response.data;
          } else {
            // this.uikitService.notifyError(response)
            // $state.go('profile.404', {}, { reload: true });
          }
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
        }
      )
    );
  }

  getEpisodesApi(id: string): Observable<any> {
    const getEpisodesUrl = environment.apiUrl + environment.version + assetContentSeries + id;
    const params = new HttpParams()
      .set('d_type', 'web')
      .set('version','v1');
    return this.http.get(getEpisodesUrl, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            return response.data;
          } else {
            // this.uikitService.notifyError(response)
            // $state.go('profile.404', {}, { reload: true });
          }
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
        }
      )
    );
  }

  watchCountApi(id: any, contentType: any) {
    const watchCountUrl = environment.apiUrl + environment.version + watchCount;
    const userId: string = this.storageService.getLocalStore('u_id');
    const body = {
      content_id: id,
      u_id: userId,
      content_type: contentType,
      d_type: 'web'
    };
    return this.http.put(watchCountUrl, body).pipe(
      map((response: any) => {
        return response;
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  hoursToDaysConversion(hours) {
    if (hours > 48) {
      const days = Math.floor(hours / 24);
      const remainingHours = hours % 24;
      if (remainingHours === 0) {
          return days + " days";
      } else {
          return days + " days " + remainingHours + " hours";
      }
    } else {
      return hours + " hours";
    }
  }

  userHistoryApi(contentId: any) {
    const userHistoryUrl = environment.apiUrl + environment.version + userHistory;
    const userId: string = this.storageService.getLocalStore('u_id');
    const body = {
      content_id: contentId,
      u_id: userId,
      d_type: 'web'
    };
    return this.http.post(userHistoryUrl, body).pipe(
      map((response) => {
        return response;
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  userContinueWatchingApi(data) {
    const userContinueWatchingUrl = environment.apiUrl + environment.version + userContinueWatch;
    const profileId = this.storageService.getSessionStore('profileId');
    const uId = this.storageService.getLocalStore('u_id');
    data['profile_id'] = profileId ?  profileId : uId;
    return this.http.post(userContinueWatchingUrl, data).pipe(
      map((response) => {
        return response;
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  getSeriesPersonData(id: string) {
    const contentURL = environment.apiUrl + environment.version + contentPersonDataSeries + id;
    const params = new HttpParams()
      .set('d_type', 'web');
    return new Promise((resolve, reject) => {
      return this.http.get(contentURL, { params }).subscribe(
        (response: any) => {
          resolve(response);
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
          resolve(error);
        }
      );

    });
  }

  getTrailer(id) {
    const contentURL = environment.apiUrl + environment.version + trailerInfo + id;
    const params = new HttpParams()
      .set('d_type', 'web')
      .set('region', 'int')
      .set('version','v1');
    return new Promise((resolve, reject) => {
      return this.http.get(contentURL, { params }).subscribe(
        (response: any) => {
          resolve(response);
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
          resolve(error);
        }
      );

    });
  }

  validateParentalPin(data){
    const validatePinURL = environment.apiUrl + environment.version + contentPinValidation;
    const profileId = this.storageService.getSessionStore('profileId');
    const uId = this.storageService.getLocalStore('u_id');
    data['profile_id'] = profileId ?  profileId : uId;
    return new Promise((resolve) => {
      return this.http.post(validatePinURL, data).subscribe(
        (response: any) => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  async translateSelectedLocale(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let selectedLocale;
      if (this.appService.playerOptionsData) {
        selectedLocale =  this.configService.localeKeys?.hasOwnProperty((this.appService.playerOptionsData[this.configService?.locales[this.configService?.localeIndex || 0].lang_code.trim().split('-')[0]]?.iso_language_name).toLowerCase()) ? (this.configService.localeKeys?.[(this.appService.playerOptionsData[this.configService?.locales[this.configService.localeIndex].lang_code.trim().split('-')[0]].iso_language_name).toLowerCase()]) : (this.configService.localeKeys?.[this.configService.locales[this.configService.localeIndex].lang_name] || 'Select Locale');
        resolve(selectedLocale);
      } else {
        reject('No data available');
      }
    });
  }

  translateLanguage(locales) {
      if (this.appService.playerOptionsData) {
        locales.map(locale => {
          if (this.appService.playerOptionsData.hasOwnProperty(locale.lang_code.trim().split('-')[0])) {
            locale.translatedLanguage = this.configService.localeKeys?.hasOwnProperty(this.appService.playerOptionsData[locale.lang_code.trim().split('-')[0]].iso_language_name.toLowerCase()) ? this.configService.localeKeys?.[this.appService.playerOptionsData[locale.lang_code.trim().split('-')[0]].iso_language_name.toLowerCase()] : (this.appService.playerOptionsData[locale.lang_code.trim().split('-')[0]].iso_language_name);
          } else {
            locale.translatedLanguage = locale.lang_code.trim().split('-')[0];
          }
          return locale;
        });
      }
    return locales;

  }
  establishWebSocketConnection(): void {
    this.connectUrl = `${this.webSocketUrl}?Auth=Bearer${this.storageService.getLocalStore('a_t')}`;
    this.socket = new WebSocket(this.connectUrl);
    this.socket.onopen = () => {
      // Connection opened - send initial data
      if (this.socket.readyState === WebSocket.OPEN) {
        const initialData = {
          action: 'update_data',
          data: {
            user_id: this.storageService.getLocalStore('u_id'),
            device_id: this.storageService.getLocalStore('d_id'),
            content_id: this.contentId,
          },
        };
        this.socket.send(JSON.stringify(initialData));
      }
      // Start sending "PING" messages at regular intervals
      this.pingIntervalId = setInterval(() => {
        const intervalMsg = {
          "action": "ping"
        }
        this.socket.send(JSON.stringify(intervalMsg));
      }, (this.activeRouteInterval * 1000));
       // Set the connection timeout
      this.connectionTimer = setTimeout(() => {
        this.closeWebSocketConnection();
        this.establishWebSocketConnection(); // Open a new connection
    }, this.connectionTimeout);
    };
    this.socket.onmessage = (event) => {
      // Handle received messages here
      let message = JSON.parse(event.data);
     if(((message.status_code == 500) && (message.error.code == 2501))){
      this.socketResponse.next(true);
      this.concurrentErrorMsg.next(true);
      this.liveConcurrentErrorMsg.next(true);
    }
    if(((message.status_code == 500) && (message.error.code == 2500))){
      this.closeWebSocketConnection();
      this.router.navigateByUrl('/home');
    }
    };
    this.socket.onerror = (error) => {
      // Handle WebSocket errors here
      console.error('WebSocket error:', error);
    };
    this.socket.onclose = () => {
      // WebSocket connection closed - stop sending "PING" messages
      clearInterval(this.pingIntervalId);
      // Clear the connection timeout
      clearTimeout(this.connectionTimer);
    };
  }
  closeWebSocketConnection(): void {
    this.socketResponse.next(false);
    if (this.socket) {
      clearInterval(this.pingIntervalId);
      this.socket.close();
      this.socket = undefined;
      clearTimeout(this.connectionTimer);
    }
  }

  resetScreen() {
    switch (true) {
      case this.windowWidth > 1800:
        this.playerHeight = 600;
        this.playerWidth = this.windowWidth * 0.75;
        this.playerHolaWidth = this.windowWidth * 0.75;
        break;
      case (this.windowWidth > 1450 && this.windowWidth < 1801):
        this.playerHeight = 600;
        this.playerWidth = this.windowWidth * 0.75;
        this.playerHolaWidth = this.windowWidth * 0.75;
        break;
      case (this.windowWidth > 991 && this.windowWidth < 1451):
        this.playerHeight = 450;
        this.playerWidth = '100%';
        this.playerHolaWidth = this.windowWidth * 0.85;
        break;
      case (this.windowWidth > 767 && this.windowWidth < 992):
        this.playerHeight = 500;
        this.playerWidth = this.windowWidth * 0.75;
        this.playerHolaWidth = this.windowWidth * 0.75;
        break;
      case (this.windowWidth > 479 && this.windowWidth < 768):
        this.playerHeight = 350;
        this.playerWidth = '100%';
        this.playerHolaWidth = this.windowWidth * 0.85;
        break;
      case (this.windowWidth < 480):
        this.playerHeight = 350;
        this.playerWidth = '100%';
        this.playerHolaWidth = this.windowWidth * 0.85;
        break;
      default:
        this.playerHeight = 450;
        this.playerWidth = '100%';
        this.playerHolaWidth = this.windowWidth * 0.85;
        break;
    }
  }

  // TVOD
  getTvodInfo(bannerSlideObj) {
    if (bannerSlideObj?.is_tvod) {
      let updatedTvodData: any = {};
      const userPurchases = this.storageService.getLocalStore('userPurchases');
      const purchasedContent = bannerSlideObj?.is_series ? userPurchases[bannerSlideObj.series_slug] : (bannerSlideObj?.is_livechannel ? (userPurchases[bannerSlideObj.slug] || userPurchases[bannerSlideObj.content_slug] ) : userPurchases[bannerSlideObj.content_slug]);
      if (purchasedContent) {
        if (purchasedContent.buy && Object.keys(purchasedContent.buy)?.length) {
          updatedTvodData.isBought = true;
          updatedTvodData.buy = purchasedContent.buy;
          updatedTvodData.tvodText = '';
        } else if (purchasedContent.rent && Object.keys(purchasedContent.rent)?.length) {
          updatedTvodData.isRented = true;
          updatedTvodData.rent = purchasedContent.rent;
          updatedTvodData.tvodText = purchasedContent.rent.expires_in;
        }
      }
      return updatedTvodData;
    }
  }
}
